.glide {
  padding-bottom: 1rem;

  &__track {
    .glide__slides {
      margin-bottom: 30px;

      .glide__slide {
        height: auto;

        .project-thumbnail {
          height: 100%;
        }
      }
    }
  }

  &__arrows {
    opacity: 0;
    transition: opacity $transition;
  }

  &:hover,
  &:focus {
    .glide__arrows {
      opacity: 1;
    }
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 4rem);
    width: 6rem;
    height: 6rem;
    background: rgba($white, 0.75);
    border: none;
    border-radius: 0;
    margin: 1rem 3rem 2rem;
    padding: 0 1rem;
    color: $brand_onyx;
    line-height: 1;
    font-size: $font-size-base * 1.3;
    box-shadow: none;
    border-radius: 50%;

    &:focus,
    &:active,
    &:hover {
      background: $white;
      outline: none;
      box-shadow: none;
      text-decoration: none;
    }

    &--right {
      right: 0;

      &::after {
        content: "\2192";
        display: inline-block;
      }
    }

    &--left {
      left: 0;

      &::after {
        content: "\2190";
        display: inline-block;
      }
    }

    @media screen and (min-width: $screen-sm-min) {
      top: calc(50% - 5rem);
      bottom: 0;
    }
  }
}

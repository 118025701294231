p {
  margin: 0 0 1.5rem 0;

  & + h1,
  & + h2,
  & + h3 {
    margin-top: 2rem;
  }

  b,
  strong {
    font-weight: $font-weight-bold;
  }

  i,
  em {
    font-style: italic;
    font-weight: inherit;
  }
}

a {
  color: $brand_skyward;
  font-weight: $font-weight-bold;
  text-decoration: none;

  transition: color $transition;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
  }
}

h1 {
  margin: 0.25rem 0;
  font-family: $font_titillium;
  font-size: $font-size-base * 2;
  font-weight: $font-weight-bold;
  line-height: 1.25;
  letter-spacing: 0.5px;

  @media screen and (max-width: $screen-sm-max) {
    line-height: 1.25;
  }
}

h2 {
  margin: 0 0 0.5em 0;
  font-family: $font_titillium;
  font-weight: $font-weight-bold;
  font-size: $font-size-base * 1.875;
  line-height: 1.5;
  text-transform: uppercase;
}

h3 {
  margin: 0 0 0.5em 0;
  font-size: $font-size-base * 1.75;
  font-family: $font_titillium;
  font-weight: $font-weight-bold;
  line-height: 1.25;
}

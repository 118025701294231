.social-links {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__link {
    display: flex;
    align-items: center;
    list-style: none;
  }
}

.icon-link {
  display: block;

  .icon {
    display: block;
    width: 100%;
    height: auto;

    &:hover,
    &:active {
      path {
        fill: $brand_starlight;
      }
    }

    &--facebook {
      &:hover,
      &:active {
        path {
          fill: $vendor_facebook;
        }
      }
    }

    &--instagram {
      &:hover,
      &:active {
        path {
          fill: $vendor_instagram;
        }
      }
    }

    &--twitter {
      &:hover,
      &:active {
        path {
          fill: $vendor_twitter;
        }
      }
    }

    &--tumblr {
      &:hover,
      &:active {
        path {
          fill: $vendor_tumblr;
        }
      }
    }

    &--youtube {
      &:hover,
      &:active {
        path {
          fill: $vendor_youtube;
        }
      }
    }
  }
}

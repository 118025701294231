/* =================================================================================
~ Reboot - combination of normalization, reset, and defaults
================================================================================= */

// Set border box sizing and reset margin/padding on all elements
*, *:before, *:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%; // Ensures 1rem = 10px
  -webkit-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in iOS
  -ms-text-size-adjust: 100%; // Prevent adjustments of font size after orientation changes in IE on Windows Phone
  -ms-overflow-style: scrollbar; // Setting @viewport causes scrollbars to overlap content in IE11 and Edge, so we force a non-overlapping, non-auto-hiding scrollbar to counteract
  -webkit-tap-highlight-color: rgba($brand_void, 0); // Change the default tap highlight to be completely transparent in iOS
}

body {
  background-color: $body-bg;
  color: $text-color;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  text-align: left;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Shim for HTML5 structural elements to display correctly ( IE10, older browsers )
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

// Headlines
h1, h2, h3, h4, h5, h6 {
  color: inherit;
  font-family: $font-family-base;
  font-weight: $font-weight-normal;
}

// Links
a {
  color: inherit;
  text-decoration: none;
}

// Address
address {
  font-style: normal;
  line-height: inherit;
}

// Prevent `sub` and `sup` elements from affecting the line height in
sub, sup {
  position: relative;
  font-size: .85em;
  line-height: 0;
  vertical-align: baseline;
}
sub { bottom: -.25em; }
sup { top: -.5em; }

// Code
pre, code {
  font-family: $font-family-monospace;
  font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

// Lists
ul, ol {
  list-style: none;
}

.block-content {
  ul, ol {
    list-style: disc;
    margin-left: 2rem;
  }
}

// Images
img {
  border-style: none;
  display: block;
  max-width: 100%;
  height: auto;
}
svg:not(:root) {
  overflow: hidden; // Hide the overflow in IE
}

// Forms
label {
  display: inline-block;
}
button {
  background: transparent;
  border: none;
  border-radius: 0;
}
input,
button,
select,
optgroup,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
textarea {
  overflow: auto;
  resize: vertical;
}

// Tables
table {
  border: 0;
  border-collapse: collapse;
  empty-cells: show;
  font-size: 1em;
}
caption, th, td {
  vertical-align: top;
  text-align: inherit;
  font-weight: normal;
}

// Suppress the focus outline on elements that cannot be accessed via keyboard
[tabindex="-1"]:focus {
  outline: 0 !important;
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

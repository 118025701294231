.footer {
  background: #003348;
  color: $white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-family: $font_titillium;
  -webkit-font-smoothing: antialiased;

  &__content {
    display: flex;
    align-items: center;
    font-size: $font-size-base * 0.9;

    &--left {
      flex-direction: column;
      width: 60%;
      display: flex;
      justify-content: space-between;

      @media screen and (min-width: $screen-sm-min) {
        flex-direction: row;
      }
    }
  }

  &__social {
    display: flex;
    align-items: flex-start;
    padding-top: 2rem;
    flex-direction: row;

    @media screen and (min-width: $screen-md-min) {
      padding-top: 0;
      flex-direction: column;
    }
  }

  &__copy {
    p {
      margin: 0;
      margin-left: 10px;
      font-family: $font_barlow;
      font-size: $font-size-base * 0.8;
      line-height: 1.4;
      font-weight: $font-weight-light;
    }
  }

  &__heading {
    display: block;
    margin-bottom: 0.5rem;
    color: $white;
    font-size: $font-size-base * 0.7;
    font-family: $font-barlow;
  }
}

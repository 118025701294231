fieldset {
  border: none;
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  background: transparent;
  border: 2px solid $gray-lighter;
  font-size: $font-size-base * 0.9;
  line-height: 1;
  margin-bottom: 3rem;
  padding: 1.2rem 2.5rem;
  width: 100%;

  &:focus {
    outline: none;
  }

  // Small and larger
  @media screen and (min-width: $screen-sm-min) {
    font-size: $font-size-base * 1;
  }
}

label {
  font-weight: $font-weight-bold;
}

textarea {
  line-height: 1.2;
}

button[type="submit"] {
  width: 100%;
}

input[placeholder] {
  text-overflow: ellipsis;
}
::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}

::placeholder {
  color: $gray-lighter;
  text-overflow: ellipsis;
}

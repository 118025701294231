.card {
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid #A6ADAD;
  word-break: break-word;

  &__link {
    display: block;
    color: #4d4d4d;
    text-decoration: none;
  }
}

/* =================================================================================
~ Hamburger Icon
@author       Jon Suh <@jonsuh>
@website      https://jonsuh.com/hamburgers | https://github.com/jonsuh/hamburgers
@description  Hamburger menu icon with on click animation
================================================================================= */
$hamburger-layer-width: 40px;
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 8px;

.hamburger {
  display: inline-block;
  overflow: visible;
  cursor: pointer;
  transition: opacity 0.15s linear;

  .js-active & {
    .hamburger-inner,
    .hamburger-inner::before,
    .hamburger-inner::after {
      background-color: $white;
    }
  }
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $white;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  &::before,
  &::after {
    content: "";
    display: block;
  }

  &::before {
    top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }

  &::after {
    bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
  }
}

.hamburger--spin {
  .hamburger-inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.1s 0.25s ease-in,
      opacity 0.1s ease-in;
    }

    &::after {
      transition: bottom 0.1s 0.25s ease-in,
      transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  .js-active & {
    .hamburger-inner {
      transform: rotate(225deg);
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out,
        opacity 0.1s 0.12s ease-out;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease-out,
        transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

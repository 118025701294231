.past-years {
  display: flex;
  flex-wrap: wrap;
  padding: 0 10%;
  justify-content: space-between;
  margin-bottom: 40px;

  > * {
    display: inline-block;
    margin: 0 3.1rem 3rem 0;
  }
  & .block-challenges 
  & .cta {
    padding: 0.75rem 2.25rem;
  }
  @media screen and (min-width: $screen-md-min) {
    padding: 0;
  }
}

.footer-menu {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  color: $white;
  font-family: $font_titillium;
  font-size: $font-size-base * 1.125;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.5px;
  margin-bottom: 1rem;

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: center;
  }

  &__item {
    list-style: none;
    margin: 0;

    @media screen and (min-width: $screen-sm-min) {
      margin: 0 3rem 0 0;
      text-align: right;

      &:hover,
      &:focus,
      &:active {
        .menu__link {
          color: $brand_sand;
          text-decoration: none;

          &::after {
            border-top-color: $brand_sand;
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    display: block;
    padding: 1rem 0;
    color: $white;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    transition: color $transition;
    text-transform: none;
    letter-spacing: 0.03em;
    @media screen and (min-width: $screen-md-min) {
      padding: 2rem 0;
    }
    &:focus,
    &:hover {
      color: $brand_sand;
      text-decoration: none;
    }
  }
}

.project-thumbnail {
  display: block;
  width: 100%;
  background: $white;
  margin: 1rem;
  overflow: hidden;
  border: 1px solid $brand_gainsboro;

  &__image-link {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 2rem;
    transition: transform $transition;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        $brand_skyward,
        lighten($brand_skyward, 13.5%)
      );
      opacity: 0;

      transition: opacity $transition;
    }

    &:hover,
    &:active {
      transform: scale(1.01);
    }

    img {
      width: 100%;
    }
  }

  &__link {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: $brand_darkgreen;
    text-transform: capitalize;
    letter-spacing: 0.01em;
    font-size: $font_size_base * 1.2;
    font-family: $font_titillium;
    text-overflow: ellipsis;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &__title {
    display: block;
    font-size: $font-size-base * 0.75;
    line-height: 1.4;
    padding: 0 2.5rem;
  }

  &__team,
  &__location {
    display: block;
    font-size: $font-size-base * 0.8;
    font-family: $font_barlow;
    letter-spacing: 0.01em;
    line-height: 1.4;
    padding: 0 2.5rem;
    color: $brand_outerspace;
  }
  &__location {
    padding-bottom: 3rem;
  }
}

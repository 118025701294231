.event {
  &__heading{
    border-left: 4px solid #d4d4d4;
    padding-left: 4rem;
    margin-bottom: 5rem;
  }

  &__title {
    font-size: 2.75rem;
    margin: 0;
    margin-bottom: 3rem;
    color: rgba(30, 30, 30, 1);
    letter-spacing: 0.01em;
    line-height: 23px;
    text-transform:  none;
  }

  &__description {
    font-family: 'Barlow';
    font-size: 1.5rem;
    color: #4c4c4c;
    letter-spacing: .03em;
    line-height: 19px;
  }
}

.award {
  height: 100%;
  padding-top: 117px;

  &__banner {
    font-family: 'Barlow';
    position: absolute;
    top: 0;
    width: 100%;
    height: 117px;
    color: #041615;
    padding: 2rem 2rem;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(-180deg, rgba(197, 205, 205, 1) 0%, rgba(255, 255, 255, 1) 100%);

    &:before,
    &:after {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      top: 117px;
    }

    &:before {
      border-width: 5px 10px 0  0;
      border-color: rgba(166, 173, 173, 1) transparent transparent transparent;
      right: 0;
    }

    &:after {
      border-width: 0 10px 5px 0;
      border-color: transparent rgba(166, 173, 173, 1) transparent transparent;
      left: 0;
    }
  }

  &__link {
    display: flex;
    align-items: flex-start;
    color: #4d4d4d;
    text-decoration: none;
  }

  &__meta {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.03em;
    line-height: 16px;
  }

  &__title {
    margin: 0 0 1rem;
    font-size: 2rem;
    letter-spacing: 0.01em;
    line-height: 23px;
    font-weight: 700;
  }

  &__icon {
    margin: 0 2rem 0 0;

    img {
      display: block;
    }
  }

  .card {
    margin: 0 11px;
  }

  .team {
    &__photo {
      display: block;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: block;
        opacity: 0.4;
      }
    }
  
    &__meta {
      padding: 1em;
      color: rgba(76, 76, 76, 1);
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.03em;
      line-height: 19px;
    }
  
    &__name {
      font-family: 'Titillium Web';
      font-size: 2.3rem;
      font-weight: 700;
      color: rgba(30, 30, 30, 1);
      letter-spacing: 0.01em;
      line-height: 23px;
      margin: 0 0 0.5em 0;
    }
  }
}


.ties {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: calc(100% - 65px);
    top: 20px;
    left: 0;
    z-index: 0;

    @media screen and (min-width: 768px) {
      background: #DDD;
    }
  }

  & > * {
    position: relative;
    z-index: 1; 
  }

  &__meta {
    width: 100%;
    align-self: center;
    margin-bottom: 3rem;

    @media screen and (min-width: 768px) {
      width: 49%;
      margin-left: 2%;
      text-align: center;
    }

    @media screen and (min-width: 1024px) {
      width: 23.5%;
      margin-left: 2%;
      text-align: center;
    }

    img {
      display: inline;
    }
  }

  &__title {
    font-family: 'Titillium Web';
    font-size: 2.3rem;
    font-weight: 700;
    color: rgba(30, 30, 30, 1);
    letter-spacing: 0.01em;
    line-height: 23px;
    margin: 0 0 0.5em 0;
  }
}

.data-cube-container {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: calc(100% - 65px);
    top: 20px;
    left: 0;
    z-index: 0;

    @media screen and (min-width: 768px) {
      background: #DDD;
    }
  }

  & > * {
    position: relative;
    z-index: 1; 
  }

  .grid {
    max-width: 800px;
  }
}

.data-cube {
  &__heading {
    max-width: 800px;
    margin: auto;
    padding: 0 0 2.5rem;

    @media screen and (min-width: 768px) {
      padding: 10rem 0 5rem;
    }
  }
  &__title {
    display: flex;
    align-items: baseline;
    font-size: 2.75rem;
    margin: 0;
    margin-bottom: 1.8rem;
    color: rgba(30, 30, 30, 1);
    letter-spacing: 0.01em;
    line-height: 23px;
    text-transform:  none;

    img,
    span {
      display: block;
    }

    img {
      margin: 0 18px 18px 0;
    }
  }

  &__description {
    font-size: 1.8rem;
  }
}

.data-cube-container[data-count="1"] {
  display: flex;
  flex-wrap: wrap;

  &:before {
    height: calc(100% - 85px);
  }

  @media screen and (min-width: 768px) {
    padding: 0 5rem;
  }

  .grid {
    width: 100%;
    max-width: 100%;

    .column-third {
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      width: 50%;
    }

    @media screen and (min-width: 1024px) {
      width: 25%;
    }
  }

  .data-cube__heading {
    width: 100%;
    max-width: 100%;
    padding: 0;

    @media screen and (min-width: 768px) {
      width: 49%;
      margin-right: 1%;
    }

    @media screen and (min-width: 1024px) {
      width: 74%;
      margin-right: 1%;
    }
  }
}

.data-cube-container[data-count="2"] {
  display: flex;
  flex-wrap: wrap;

  &:before {
    height: calc(100% - 85px);
  }

  @media screen and (min-width: 768px) {
    padding: 0 5rem;
  }

  .grid {
    width: 100%;
    max-width: 600px;

    .column-third {
      width: 100%;
    }

    @media screen and (min-width: 1024px) {
      width: 50%;
      max-width: 100%;

      .column-third {
        width: 49%;
      }
    }
  }

  .data-cube__heading {
    width: 100%;
    max-width: 100%;
    padding: 0;

    @media screen and (min-width: 1024px) {
      width: 49%;
      margin-right: 1%;
    }
  }
}

.team-container {
  font-family: "Barlow";
  background-color: #ffffff;
  color: #041615;
  vertical-align: top;
  height: 100%;

  & .team {
    &__photo {
      display: block;
      height: 0;
      padding-bottom: 56.25%;
      position: relative;
      background-size: cover;
      background-repeat: no-repeat;

      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        display: block;
        opacity: 0.4;
      }
    }

    &__info {
      display: flex;
      flex: 1;
      padding: 20px 15px;
      min-height: 155px;
      flex-direction: column;
    }

    &__link {
      display: flex;
      text-decoration: none;
    }

    &__text {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__name {
      font-family: "Titillium Web";
      font-size: 2rem;
      letter-spacing: 0.01em;
      line-height: 21px;
      font-weight: 700;
      margin: 0 15px 8px 0px;
    }

    &__moto {
      font-size: 1.5rem;
      letter-spacing: 0.01em;
      line-height: 19px;
      padding-right: 8px;
    }

    &__challenge {
      width: 100%;
      margin: 2rem 0;
      font-weight: 600;
      display: inline-block;
      padding: 10px;
      text-decoration: none;
      transition: box-shadow 0.2s;
      background-color: #efefef;
      border-radius: 6px;
      font-size: 1.4rem;
      color: #041615;
      letter-spacing: 0.03em;
      line-height: 19px;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: rgba(239, 239, 239, .6);
      }
    }

    &__meta {
      a {
        color: inherit;
      }

      p {
        margin-bottom: 0;
        color: #a6adad;
      }
    }

    &__location,
    &__members {
      font-size: 1.4rem;
      font-weight: 500;
      color: #a6adad;
      letter-spacing: 0.01em;
      line-height: 19px;
    }

    .divider {
      display: inline-block;
      margin: 0 0.5rem;
    }
  }
}

.photo-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-size: cover;

  img {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.theme--green {
  .event__heading{
    border-color: #00664c;
  }
  .team__photo::after {
    background: #00664c;
  }
}

.theme--red {
  .event__heading{
    border-color: #d51a2f;
  }
  .team__photo::after {
    background: #d51a2f;
  }
}

.theme--blue {
  .event__heading{
    border-color: #4082cd;
  }
  .team__photo::after {
    background: #4082cd;
  }
}

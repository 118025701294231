.sub-menu {
  color: $gray-lighter;
  font-size: $font-size-base * 1.8;
  text-transform: uppercase;
  line-height: 1.25;
  letter-spacing: 0.5px;

  @media screen and (min-width: $screen-lg-min) {
    flex-direction: row;
    background: $white;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    font-size: $font-size-base * 1.1;

    &--slide-down {
      position: absolute;
      left: 0;
      top: 100%;
      z-index: -1;
      width: 100%;
      overflow: hidden;
      height: 0;
      opacity: 0;
      transform: translate3d(0, 5px, 0);
      transition: transform $transition, opacity $transition;
    }
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0;

    @media screen and (min-width: $screen-lg-min) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__parent {
    display: none;
    margin: 1rem 0;
    border-bottom: 4px solid transparent;
    font-weight: $font-weight-demi;
    font-size: $font_size_base * 1.1;
    text-transform: none;
    color: #a6adad;
    letter-spacing: 0.05em;

    @media screen and (min-width: $screen-lg-min) {
      display: inline-block;
      margin: 0;
      font-size: $font-size-base * 1.1;
      margin-left: 0;
    }
  }

  &__item {
    list-style: none;
    margin: 0 0 3rem 0;

    @media screen and (min-width: $screen-lg-min) {
      margin: 0 0 0 3rem;
      text-align: right;
    }

    &.active {
      .sub-menu__link {
        border-color: $brand_darkgreen;
        @media screen and (min-width: $screen-lg-min) {
          color: $brand_darkgreen;
        }
      }
    }
  }

  &__link {
    display: block;
    border-bottom: 4px solid transparent;
    color: $white;
    transition: border-color $transition;
    text-transform: none;
    font-weight: $font-weight-demi;
    font-size: 1.8rem;

    @media screen and (min-width: $screen-lg-min) {
      padding: 3.5rem 0 3rem;
      color: #041615;

      &:hover,
      &:focus,
      &:active {
        color: #00664c;
        border-color: #00664c;
        text-decoration: none;
      }

      &.active {
        &:hover {
          color: $brand_oldgold;
        }
      }
    }
  }
}

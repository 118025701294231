/* =================================================================================
~ Embed - maintains aspect ratio responsively - Credit: Nicolas Gallagher and SUIT CSS
================================================================================= */

.embed {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &:before {
    display: block;
    content: "";
    padding-top: percentage(1 / 1);
  }

  &__item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  &--21by9 {
    &:before {
      padding-top: percentage(9 / 21);
    }
  }

  &--16by9 {
    &:before {
      padding-top: percentage(9 / 16);
    }
  }

  &--4by3 {
    &:before {
      padding-top: percentage(4 / 3);
    }
  }
}

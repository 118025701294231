/* =================================================================================
~ Fonts
================================================================================= */

// Google Fonts Oswald, Barlow, and Titillium Web import

@import url("https://fonts.googleapis.com/css?family=Oswald:300,400,500,600,700|Titillium+Web:300,400,400i,600,700,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@300;400;500;600;700&display=swap");

// SVG Icons

[class^="icon-"] {
  path {
    fill: $white;
    transition: fill $transition;
  }
  &:hover g {
    opacity: 1;
  }
}

button,
.btn {
  @include btn();

  &--green {
    @include btnGreen();
  }

  &--grey {
    @include btnGrey();
  }
}









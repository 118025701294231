.accordion {
  max-width: 80rem;
  margin: 0 auto;

  &__title {
    font-weight: $font-weight-demi;
    font-size: $font-size-base * 1.5;
    font-family: $font_titillium;
    letter-spacing: .02em;
    line-height: 7.6rem;
    color: $brand-nearblack;
    text-transform: none;
  }

  &__item {
    border-bottom: 1px solid $brand-darkgray;

    &-title {
      cursor: pointer;
      display: block;
      font-family: $font_titillium;
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      line-height: $font-size-base;
      color: $brand-darkgray;
      padding: 3.8rem 9rem 3.8rem 0;
      text-transform: uppercase;
      position: relative;

      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 1.3rem solid $brand_platinum;
        border-left: 1.3rem solid transparent;
        border-right: 1.3rem solid transparent;
        position: absolute;
        right: 5rem;
        top: 50%;
        transform: translateY(-50%);
        transition: transform $transition;
      }

      &:not(.collapsed) {
        &::after {
          transform: rotate(-180deg) translateY(50%);
        }
      }
    }

    &-content {
      @include bodyParagraph;

      &-body {
        padding-bottom: 3.5rem;
      }
    }
  }
}

/* =================================================================================
~ Button
================================================================================= */

@mixin btn {
  display: inline-block;
  background: $brand_skyward;
  padding: 1.25rem 1.75rem;
  color: $white;
  border-radius: 2px;
  box-shadow: 0 4px 0 darken($brand_skyward, 25%);
  font-weight: $font-weight-bold;
  font-size: $font-size-base * 0.9;
  text-align: center;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  font-family: $font_titillium;
  transition: box-shadow $transition, background $transition, color $transition;

  &:focus,
  &:hover {
    outline: none;
    text-decoration: none;
  }
}

@mixin btnGreen {
  background: $brand_viridian;
  box-shadow: 0 4px 0 $brand_sacrementogreen;
  color: $brand_onyx;
  &:hover {
    background: $brand_mediumaquamarine;
    color: $brand_onyx;
    box-shadow: 0 4px 0 $brand_mediumaquamarine;
  }
  &:focus {
    color: $brand_onyx;
  }
}

@mixin btnGold {
  background: transparent;
  box-shadow: 0 4px 0 $brand_darkgoldenrod;
  border: 2px solid $brand_darkgoldenrod;
  border-radius: 2px;
  padding: 1rem 3rem;
  font-size: $font-size-base * 0.7;
  letter-spacing: .4px;
  color: $brand_nearblack;
  transition: all $transition;
  &:hover {
    background: $brand_citrine;
    border: 2px solid $brand_citrine;
    box-shadow: 0 4px 0 $brand_citrine;
  }
}

@mixin btnGrey {
  background: $gray-light;
  box-shadow: 0 4px 0 darken($gray-light, 25%);
  color: $brand_inkydepths;
}

@mixin btnGreenTransparent {
  background: transparent;
  color: $brand_darkgreen;
  border: 1px solid $brand_pinegreen;
  border-bottom: none;
  box-shadow: 0 4px 0 $brand_pinegreen;
  &:hover {
    background: $brand_pinegreen;
    border: 1px solid $brand_pinegreen;
    border-bottom: none;
    color: $white;
    box-shadow: 0 4px 0 $brand_pinegreen;
  }
}

/* =================================================================================
~ Clearfix
================================================================================= */

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

/* =================================================================================
~ Bio Orbit Effect
================================================================================= */

@mixin orbits($selector, $count) {
  #{$selector} {
    &::after {
      content: "";
      display: block;
      width: 1500px;
      height: 1500px;
      border: 2px dotted #0a0e21;
      position: absolute;
      left: calc(-750px + 11.5%);
      top: 50%;
      z-index: 0;
      border-radius: 50%;
    }
  }

  $base-diameter: 1000px;
  $rate-of-change: 500px;
  @for $i from 1 to $count {
    :nth-last-child(#{$i}) {
      #{$selector} {
        &::after {
          content: "";
          display: block;
          width: #{$base-diameter + $rate-of-change * $i};
          height: #{$base-diameter + $rate-of-change * $i};
          border: 2px dotted #0a0e21;
          position: absolute;
          left: calc(
            #{($base-diameter + $rate-of-change * $i) / 2 * -1} + 11.5%
          );
          top: 50%;
          z-index: 0;
          border-radius: 50%;
        }
      }
    }
  }
}

@mixin bodyParagraph {
  font-family: $font_barlow;
  font-size: $font-size-base * .7;
  color: $brand_nearblack;
  line-height: 1.9rem;
  letter-spacing: .01em;
}

@mixin greenArrowLink {
  color: $brand_mediumgreen;
  font-weight: 600;

  &::after {
    content: "\2192";
    display: inline-block;
    margin: 0 0 0 0.5rem;
  }

  &:hover {
    border-bottom: 1px solid $brand_mediumgreen;
  }
}

.container {
  width: 100%;
  margin: auto;
  padding: 0 2rem;
  max-width: 1040px;

  &--wide {
    max-width: 1240px;
  }

  &--fullWidth {
    max-width: 100%;
    padding: 0;
  }
}

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--center {
    align-items: center;
  }

  @media screen and (min-width: $screen-sm-min) {
    flex-direction: row;
    align-items: center;
  }
}

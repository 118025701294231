.hero {
  position: relative;
  min-height: 40vh;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: $white;
  background-image: linear-gradient(
    -90deg,
    $transparent 0%,
    $white 30%,
    $brand_isabelline 100%
  );
  -webkit-font-smoothing: antialiased;

  &__layer-1 {
    position: relative;
    z-index: 1;
  }

  &__layer-2 {
    display: none;
    position: absolute;
    z-index: auto;
    top: 0;
    bottom: 0;
    width: 100%;

    @media screen and (min-width: $screen-md-min) {
      display: block;
    }
  }

  &__d3--globe-link {
    position: absolute;
    right: 0px;
    margin-right: -300px;
    z-index: 2;
  }

  &__content {
    margin: 2rem 0;
    @media screen and (min-width: $screen-lg-min) {
      margin-right: 12em;
    }
    @media screen and (min-width: $screen-xl-min) {
      margin-right: 0;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 151px);
    max-width: 1240px;
    margin: auto;
    padding: 0 2rem;


    .title--h2 {
      font-size: $font-size-base * 1.8;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      line-height: 1.2;
      color: $brand_nearblack;
      text-transform: none;

      @media screen and (min-width: $screen-md-min) {
        max-width: 40%;
      }
      @media screen and (min-width: $screen-lg-min) {
        max-width: 80%;
      }
    }
    
    .title--h3,
    .title--h4 {
        letter-spacing: 0.1em;
        line-height: 1.2;
        font-weight: $font-weight-bold;
        font-family: $font_titillium;
        text-transform: uppercase;
        font-size: $font-size-base;
      }

    .title--h3 {
      color: $brand_mediumgreen;
      margin-top: 5rem;

      @media screen and (min-width: $screen-md-min) {
        max-width: 40%;
        margin-top: 0;
      }
      @media screen and (min-width: $screen-lg-min) {
        max-width: 80%;
      }
    }
    
    .title--h4 {
      color: $brand_mordantred;
      margin-bottom: 10px;
    }

    .block-content {
      p {
        color: $brand_nearblack;
        letter-spacing: 0.2px;
        line-height: 1.5;
        font-family: $font_barlow;
        font-size: $font-size-base * .9;
        margin-bottom: 25px;

        @media screen and (min-width: $screen-md-min) {
          max-width: 40%;
        }
        @media screen and (min-width: $screen-lg-min) {
          max-width: 60%;
        }
      }
      a {
        @include greenArrowLink;
      }
    }

    .richtext-image {
      &.left {
        display: inline-block;
        margin-right: 4%;
        margin-bottom: 0;
        vertical-align: middle;
        max-width: 15%;

        + p,
        + h2,
        + h3,
        + h4 {
          display: inline-block;
          vertical-align: middle;
          margin-bottom: 0;
          max-width: 80%;
          @media screen and (min-width: $screen-md-min) {
            max-width: 35%;
          }
          @media screen and (min-width: $screen-lg-min) {
            max-width: 55%;
          }
        }
      }
    }

    .cta {
      margin-bottom: 4rem;
      margin-right: 2rem;
    }

    @media screen and (min-width: $screen-md-min) {
      flex-direction: row;
      justify-content: flex-start;
    }

    @media screen and (min-width: $screen-lg-min) {
      margin: auto;
    }
  }
}

.block-content{
  h2,
  h3 {
    color: $brand_void;
  }
}

.block-rich_text {
  max-width: 80rem;
  margin: 0 auto 4rem;

  &_wide {
    max-width: 100rem;
    margin: 0 auto 4rem;

    p {
      @include bodyParagraph;
    }

    a {
      @include greenArrowLink;
    }
  }

  p {
    @include bodyParagraph;
  }

  a {
    @include greenArrowLink;
  }

  h2 {
    font-family: $font_titillium;
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    line-height: $font-size-base;
    color: $brand_mediumgreen;
    margin-bottom: 2.5rem;
  }
}

.page-heading {
  margin: 4rem 8rem 2rem 0;
  font-weight: $font-weight-demi;
  font-size: $font-size-base * 2;
  font-family: $font_titillium;
  letter-spacing: .02em;
  line-height: 7.6rem;
  color: $brand-nearblack;
}


.page-header--image {
  display: flex;
  width: 100%;
  margin: 0 auto 6rem;
  padding: 2rem;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 300px;
  
  .page-heading {
    font-family: 'Titillium Web';
    font-size: 5rem;
    font-weight: 600;
    color: rgba(30, 30, 30, 1);
    letter-spacing: 0.1em;
    line-height: 64px;
    margin: 0 0 3rem;
    max-width: 768px;
  }
  
  .page-sub-heading {
    font-family: 'Barlow';
    font-size: 1.5rem;
    color: #4c4c4c;
    letter-spacing: .03em;
    line-height: 19px;
    max-width: 768px;
  }
}

.page-content {
  background: #003348;
  overflow: hidden;
  min-height: 80vh;
  padding: 2rem 0;

  &__title {
    margin: 4rem 8rem 2rem 0;
    font-weight: $font-weight-demi;
    font-size: $font-size-base * 2;
    font-family: $font_titillium;
    letter-spacing: .02em;
    line-height: 7.6rem;
    color: $brand-nearblack;
  }

  &--default {
    .title--h3 {
      color: #766405;
      font-size: 3rem;
      line-height: 1.8;
      letter-spacing: 0.09em;
    }
  }

  &--light {
    background: $brand_blueyonder;
  }

  &--dark {
    background: $brand_darkgreen;
    color: $white;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;

    .title--h2 {
      color: $white;
      font-weight: $font-weight-light;
      line-height: 1.5;
      margin-bottom: 1em;
      text-transform: none;
      letter-spacing: 1.8px;
      font-size: $font-size-base * 2.15;
    }

    & .block-content{
      & p,
      h4 {
        font-weight: $font-weight-light;
        font-family: $font_barlow;
        line-height: 1.4;
      }
      & h4 {
        color: $brand_sand;
        margin-bottom: 4em;
        margin-top: 2em;
        & a {
          color: $brand_sand;
        }
      }
    }
  }

  &--gray {
    background: $brand_isabelline;
    .title--h2 {
      color: $brand_darkgreen;
        letter-spacing: 0.02em;
        font-size: $font-size-base * 1.7;
        text-transform: none;
        margin: 5rem 0;
    }
    & .block-content {
      & p {
        font-weight: $font-weight-base;
        font-family: $font_barlow;
        color: $brand_outerspace;
        font-size: $font-size-base * 1.05;
        line-height: 1.3;
        letter-spacing: 0.01em;
      }
    }
    padding-bottom: 20px;
  }

  &--extraPadding {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  &--extraTop {
    padding-top: 6rem;
    padding-bottom: 3rem;
  }
  
  &--extraBottom {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }

  &--fullheight {
    min-height: 100vh;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.header {
  position: relative;
  background: #003348;
  color: $white;
  padding: 3rem 0 4rem;

  @media screen and (min-width: $screen-lg-min) {
    padding: 0;
  }

  .site-logo {
    display: block;
    max-width: 240px;
  }

  .logo {
    display: block;
    width: 100%;

    .cls-1,
    .cls-2 {
      fill: $white;
      transition: fill $transition;
    }
  }
}

.site-menu {
  display: none;
  visibility: hidden;
  padding-top: 20%;
  opacity: 0;
  font-size: $font-size-base * 2;
  margin-top: 0;
  z-index: 3;
  transition: opacity $transition;

  @media screen and (min-width: $screen-lg-min) {
    display: flex;
    flex-wrap: nowrap;
    visibility: visible;
    padding: 0;
    opacity: 1;
    font-size: $font-size-base * 1.1;
    margin-top: 3rem;
  }

  .js-active & {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    overflow: scroll;
    visibility: visible;
    background: rgba($brand_phthalogreen, 0.95);
    opacity: 1;
  }

  &__toggle {
    position: absolute;
    top: 7rem;
    right: 2rem;
    z-index: 999;
    background: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    cursor: pointer;
    font-size: 2.6rem;
    transition: top $transition;

    &:focus,
    &:active,
    &:hover {
      outline: none;
      background: none;
      box-shadow: none;
    }

    @media screen and (min-width: $screen-lg-min) {
      display: none;
    }
  }
}

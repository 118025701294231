.block-image {
  margin-top: 4rem;

  img {
    width: 100%;
  }
}

.thumbnail {
  &__image-link {
    img {
      margin: 0 auto;
    }
  }

  &__link {
    color: $brand_outerspace;
    font-weight: 400;
  }
}

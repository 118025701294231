.cta {
  @include btn();

  &--green {
    @include btnGreen();
  }

  &--full {
    display: block;
  }

  &--gold {
    @include btnGold();
  }

  &--g-transparent {
    @include btnGreenTransparent();
  }

  &--arrow {
    padding-left: 3.5rem;
    padding-right: 3.5rem;

    &::after {
      content: "\2192";
      display: inline-block;
      margin: 0 0 0 1rem;
    }
  }

  @media screen and (max-width: $screen-sm-max) {
    font-size: $font-size-base * 0.9;
  }
}

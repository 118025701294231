.menu {
  display: block;
  width: 65%;
  margin: 2rem 0 4rem;
  padding: 0;
  border-bottom: 2px solid $white;
  color: $brand_blueyonder;
  font-family: $font_titillium;
  line-height: 1.25;
  letter-spacing: 0.5px;

  &:last-child {
    border-bottom: 0;
  }

  @media screen and (min-width: $screen-lg-min) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: auto;
    margin: 2rem 0 0;
    border: 0;
  }

  &--external {
    position: relative;
    .menu__item:nth-of-type(1) {
      .menu__link {
        position: relative;
        margin-top: 2em;
        @media screen and (min-width: $screen-lg-min) {
          margin-top: 0;
        }
      }
    }

    .sub-menu {
      display: none;

      @media screen and (min-width: $screen-lg-min) {
        display: block;
      }
    }
  }

  &__item {
    list-style: none;
    margin: 0 0 3rem 0;
    border-bottom: 4px solid transparent;
    transition: border-color $transition;

    @media screen and (min-width: $screen-lg-min) {
      margin: 0 0 0 3rem;
      padding: 3.8rem 0;
      text-align: right;

      &:hover,
      &:focus,
      &:active {
        border-color: #dac400;

        .sub-menu--slide-down {
          z-index: 2;
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }

        .menu__link {
          text-decoration: none;

          &::after {
            border-top-color: #dac400;
            transform: scale(1.1) rotate(-180deg);
          }
        }
      }
      &:hover {
        .menu__link {
          color: #dac400;
        }
      }
    }

    &.active,
    &.ancestor {
      .menu__link {
        &::after {
          border-top-color: #dac400;
        }
      }

      &:hover {
        .menu__link {
          color: lighten(#dac400, 25%);

          &::after {
            border-top-color: lighten(#dac400, 25%);
          }
        }
      }
    }
  }

  &__heading {
    position: absolute;
    top: 2.5rem;
    display: block;
    left: 0;
    text-align: left;
    font-size: $font-size-base * 0.7;
    font-weight: $font-weight-bold;
    color: $brand_coral;

    @media screen and (min-width: $screen-lg-min) {
      left: 3rem;
      white-space: nowrap;
    }
  }

  &__item {
    @media screen and (min-width: $screen-lg-min) {
      &:focus,
      &:hover {
        .sub-menu--slide-down {
          height: auto;
        }
      }
    }
  }

  &__link {
    position: relative;
    display: block;
    margin-bottom: 3rem;
    color: $white;
    font-weight: $font-weight-normal;
    transition: color $transition;

    @media screen and (min-width: $screen-lg-min) {
      margin-bottom: 0;
    }

    &:focus,
    &:hover {
      text-decoration: none;

      &::after {
        border-top-color: #dac400;
        transform: scale(1.1);
      }

      + .sub-menu--slide-down {
        max-height: 500px;
        opacity: 1;
        height: auto;
        transform: translate3d(0, 0, 0);
      }
    }
    &:hover {
      color: #dac400;
    }

    &--sub-menu {
      &::after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 0.5rem solid $white;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        margin-top: 0;
        margin-left: 1rem;
        vertical-align: middle;

        transition: transform $transition, border-color $transition;
      }
    }
  }
}
